import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Spin } from 'antd';
import dbMgr from '../../modules/dbManager';
import { videoPreLoader } from '../../modules/preload';
import { USER_POINTS_TO_ASK_REVIEW } from '../../modules/review';
import { addDailyGoalReached, RATE_STATE, updateUserFields } from '../../modules/user';
import DailyGoal from '../DailyGoal';
import RequestReview from '../Utils/RequestReview';
import Tutor from './Tutor';
import tutor from '../../data/tutor';
import { ACTIVATE_DEVELOP } from '../../utils';

const TutorManager = ({ user, lang, isLoading, today_time, daily_goal_reached, results, section, updateUserFields, exercise_opened }) => {

    const [executionMessages, setExecutionMessages] = useState([]);
    const [tutorMessage, setTutorMessage] = useState(null);
    const [preloading, setPreloading] = useState(true);
    const [preloadReady, setPreloadReady] = useState(!ACTIVATE_DEVELOP || false);
    const [age, setAge] = useState(1);

    const _mustShowReview = () => {
        if (!exercise_opened) {

            const uses = user.uses || 0;
            const rateState = user.rateState || 0;
            const ask = (uses > 0 && (uses % 5 === 0 || uses === 1) || user.points >= USER_POINTS_TO_ASK_REVIEW) && (rateState === null || rateState === RATE_STATE.NONE || rateState === RATE_STATE.LATER);
            console.log('TutorManager._mustShowReview:', uses, rateState, user.points, '::', ask);
            return ask;
        }
        return false;
    }

    const _mustShowTutor = () => {
        if(!ACTIVATE_DEVELOP) return false;

        const lastTutorMess = user.lastTutorMess;
        const last = results.last;
        console.log('TutorManager._mustShowTutor: lastTutorMess::', user.lastTutorMess, '::results last:', results.last, '::', results.date, results.current, results.current_level, '::exercise_opened:', exercise_opened);

        if (user.dailyGoal && !exercise_opened) {
            if (!lastTutorMess) {
                setTutorMessage(['general_welcome_l1_welcome']);
                return true;
            } else {
                console.log('TutorManager._mustShowTutor: next message...');
                if (last && last.uid === 't1' && lastTutorMess !== 't1_feedback_ok_l2_welcome') {
                    setTutorMessage(['t1_feedback_ok_l2_welcome']);
                    return true;
                }
            }
        }

        return false;
    }

    useEffect(() => {

    }, []);

    useEffect(() => {
        // console.log('TutorManager.useEffect[user] ...', user);
        setAge(user.age || 1);
        _evalWhatToShow();
    }, [user]);

    useEffect(() => {
        // console.log('TutorManager.useEffect[user] ...', user);
        _evalWhatToShow();
    }, [exercise_opened]);

    useEffect(() => {
        // console.log('TutorManager.useEffect[results] ...', results);
        _evalWhatToShow();
        if(ACTIVATE_DEVELOP) _evalWhatToPreload();
    }, [results]);

    const _evalWhatToShow = () => {
        if (results && section === 'learn') {
            const _executionMessages = [];
            if (_mustShowTutor()) _executionMessages.push('tutor');
            if (_mustShowReview()) _executionMessages.push('requestReview');
            setExecutionMessages(_executionMessages);
        } else {
            console.log('TutorManager.useEffect[results]: no results or !learn ...');
        }
    }

    const _evalWhatToPreload = () => {

        if (!preloadReady && results && section === 'learn') {
            const lastTutorMess = user.lastTutorMess;
            const last = results.last;
            const currentLevelVideos = tutor[age];

            dbMgr.isReady().then((ready) => {
                const videoSrcs = currentLevelVideos[0];
                videoPreLoader(videoSrcs, age, _onFinishPreloadHandle).preload();
            }).catch(err => {
                console.log('App.useEfect db error:', err);
            });
        }
    }

    const _onFinishPreloadHandle = (response) => {
        // console.log('videoPreLoader.preload finished...');
        console.log('App._onFinishPreloadHandle preload finished:', response);
        setPreloadReady(true);
    }

    const handleCloseMessage = (who, target) => {
        console.log('TutorManager handleCloseMessage:', who, target);
        if (who === 'tutor' && target && target.length > 0) {
            const lm = target.pop();
            updateUserFields({ lastTutorMess: lm });
        }

        if (executionMessages && executionMessages.length) {
            const _executionMessages = [...executionMessages];
            _executionMessages.shift();

            setExecutionMessages(_executionMessages);
        }
    }

    // console.log('TutorManager executionMessages:', executionMessages, executionMessages.length);

    return <>
        {preloading && !preloadReady ? <Spin size="large" className="spinner" /> : null}
        {(preloadReady) ? <DailyGoal handleCloseManager={handleCloseMessage} /> : null}
        {(preloadReady && executionMessages[0] === 'requestReview') ? <RequestReview handleCloseManager={handleCloseMessage} /> : null}
        {(preloadReady && executionMessages[0] === 'tutor') ? <Tutor lang={lang} age={age} themes={tutorMessage} handleCloseManager={handleCloseMessage} /> : null}
    </>;
}


const mapStateToProps = ({ user, response }) => ({
    user: user.user,
    lang: user.lang,
    isLoading: user.isLoading,
    today_time: user.today_time,
    daily_goal_reached: user.daily_goal_reached,
    exercise_opened: user.exercise_opened,
    results: response.data,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateUserFields,
            addDailyGoalReached
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TutorManager);
