import moment from 'moment-timezone';
// import { Random } from "random-js";
import { API_ROOT } from '../api-config';

declare let window: any;

export const OPERATIONS = ['+', '-', '*', '/'];

export const MIN_EXERS_SCREEN_HEIGHT_PORTRAIT = 640;

export const ACTIVATE_DEVELOP = false;

export const OPS_IMGS = {
  '+': 'plus',
  '-': 'minus',
  '*': 'mult',
  '/': 'division',
}

export const ANIMALS = [
  { val: 1, img: 'icons8-bee-80.png' },
  { val: 2, img: 'icons8-bird-80.png' },
  { val: 3, img: 'icons8-butterfly-80.png' },
  { val: 4, img: 'icons8-cat-butt-80.png' },
  { val: 5, img: 'icons8-crab-80.png' },
  { val: 6, img: 'icons8-dog-80.png' },
  { val: 7, img: 'icons8-duck-80.png' },
  { val: 8, img: 'icons8-horse-80.png' },
  { val: 8, img: 'icons8-ladybug-80.png' },
  { val: 9, img: 'icons8-pig-80.png' },
  { val: 10, img: 'icons8-turtle-80.png' },
  { val: 11, img: 'icons8-whale-80.png' },
];

export const isMobile = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  )
    return true;
  else return false;
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // console.log('getMobileOperatingSystem userAgent:', userAgent);

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod|AppleWebKit/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'web';
};

export const getPlatform = () => {
  if (window.cordova) return window.cordova.platformId;
  else return 'web';
}

export const checkiOSVersion = () => {
  var agent = window.navigator.userAgent,
    start = agent.indexOf('OS ');
  if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
    return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
  }
  return 0;
}

export const calculateTimeToBegin = (from, to) => {
  if (!from) return null;

  const duration = moment.duration(from.diff(to)); //.locale(lang);

  // console.log('calculateTimeToBegin:', from.format('HH:mm'), to.format('HH:mm'), 'hh mm ss:', duration.hours(), duration.minutes(), duration.seconds());

  const dayseconds = 24 * 60 * 60;
  const hourseconds = 60 * 60;
  const minseconds = 60;

  const secondstobegin =
    duration.seconds() +
    duration.minutes() * minseconds +
    duration.hours() * hourseconds +
    duration.days() * dayseconds;

  if (secondstobegin <= minseconds) {
    return {
      order: 'seconds',
      seconds: duration.seconds(),
      duration: secondstobegin
    };
  } else if (secondstobegin < hourseconds) {
    return {
      order: 'minutes',
      minutes: duration.minutes(),
      seconds: duration.seconds(),
      duration: secondstobegin
    };
  } else if (secondstobegin <= 2 * hourseconds) {
    return {
      order: 'minutes',
      minutes: duration.hours() * 60 + duration.minutes(),
      seconds: duration.seconds(),
      duration: secondstobegin
    };
  } else if (secondstobegin < dayseconds) {
    return {
      order: 'hours',
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
      duration: secondstobegin
    };
  } else {
    return {
      order: 'days',
      days: duration.days(),
      hours: duration.hours(),
      duration: secondstobegin
    };
  }
};

export const scrollToRef = (ref, options) => {
  // console.log('scrollToRef:', ref);

  if (ref) ref.scrollIntoView(options);
};

const getFullMoment = (date: string, hour: string, tz?: string) => {
  if (!date) return null;
  const pureDate = moment(date);
  const momentDateStr = pureDate.format('YYYY-MM-DD');
  const hourStr =
    date.indexOf('Z') >= 0 ? pureDate.format('HH:mm') : hour ? hour : '00:00';

  const currentTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // console.log('xxxfullMoment:', date, hour, date.indexOf('Z'));

  if (tz) {
    const fullMoment = moment.tz(`${momentDateStr} ${hourStr}`, tz);
    const localMoment = fullMoment.clone().tz(currentTZ);
    // console.log('!!fullMoment:', fullMoment.format('MM/DD HH:mm'), localMoment.format('MM/DD HH:mm'));

    return localMoment;
  } else {
    const fullMoment = moment.tz(`${momentDateStr} ${hourStr}`, currentTZ);
    return fullMoment;
  }
};

export const toDateTZ = (date, hour?) => {
  // console.log('toDateTZ:', date, hour);

  if (!date) return null;
  const fullMoment = getFullMoment(date, hour, 'Europe/Madrid');
  // console.log('toDateTZ:', fullMoment);

  return fullMoment ? fullMoment.toDate() : null;
};

export const formatMS2MMSS = (time) => {
  if (time) {
    const totSecs = time / 1000;
    const mins = Math.floor(totSecs / 60);
    const secs = Math.ceil(totSecs - mins * 60);
    return `${mins < 10 ? ('0' + mins) : mins}:${secs < 10 ? ('0' + secs) : secs}`
  } else return '00:00';
}

export const getImageUri = (aImage, fakeImg?) => {
  if (!fakeImg) fakeImg = './static/fakeimg/fake-team.png';
  if (!aImage) return fakeImg;
  else if (aImage.indexOf('http') >= 0) return aImage;
  else return `${API_ROOT}${aImage}`;
};

export const asyncGetImageUri = (imgUrl, callback) => {
  getImagePromise(`.${imgUrl}`)
    .then(photo => {
      // console.log('photo:', photo);
      callback(photo);
    })
    .catch(error => {
      // console.error('photo error:', error);
      if (imgUrl && imgUrl.indexOf('http') >= 0) callback(imgUrl);
      else callback(`${API_ROOT}${imgUrl}`);
    });
};

export const getImagePromise = (src): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      let img = new Image();
      img.onload = () => resolve(img.src);
      img.onerror = reject;
      img.src = src;
    } catch (error) { }
  });
};

const rand = (min, max) => {
  return (Math.floor(Math.pow(10, 14) * Math.random() * Math.random()) % (max - min + 1)) + min;
}

export const generateRandomNumber = (min, max, previousarray?) => {
  // console.log('generateRandomNumber:', min, max, previousarray);

  let theresult = 0;

  min = Math.ceil(min);
  max = Math.floor(max);

  // theresult = Math.floor(Math.random() * (max - min + 1)) + min;
  theresult = rand(min, max);
  if (previousarray) {
    let cont = previousarray.length;
    while (previousarray.includes(theresult) && cont > 0) {
      // theresult = random.integer(min, max); //Math.floor(Math.random() * (max - min + 1)) + min;
      theresult = Math.floor(Math.random() * (max - min + 1)) + min;
      cont--;
    }
  }

  // console.log('random theresult:', theresult);


  return theresult;
};

export const generateRandomNumberSeries = (num, init, finish, prohibited) => {
  let theresult: Array<any> = [];
  if (!prohibited) prohibited = [];
  for (let index = 0; index < num; index++) {
    const aN: any = generateRandomNumber(init, finish, prohibited);
    theresult.push(aN);
    prohibited.push(aN);
  }

  return theresult;
};

export const generateRandomOperations = (
  operations,
  numoperations,
  init,
  finish,
  ispan?,
  fspan?
) => {
  // console.log('generateRandomOperations:', operations, numoperations, init, finish, ispan, fspan );

  const theresult: Array<any> = [];

  for (let index = 0; index < numoperations; index++) {
    const anOp = shuffle(operations)[0];
    if (anOp === '+' || anOp === '*') {
      const num1 = generateRandomNumber(init, finish - parseInt(`${init / 2}`));

      const iincr = ispan ? ispan : init - num1 > 0 ? init - num1 : 0;
      const fincr = fspan
        ? fspan
        : anOp === '+'
          ? finish - num1
          : parseInt(`${finish / num1}`);

      const num2 = generateRandomNumber(iincr, fincr);
      theresult.push(num1 + anOp + num2);
    } else {
      let num1 = generateRandomNumber(
        finish - parseInt(`${init / 2}`),
        finish
      );

      const iincr = ispan ? ispan : init;
      const fincr = fspan
        ? fspan
        : anOp === '-'
          ? finish - num1
          : parseInt(`${finish / num1}`);

      let num2 = generateRandomNumber(iincr, fincr);
      if (anOp === '-' && num2 > num1) [num1, num2] = [num2, num1];

      theresult.push(num1 + anOp + num2);
    }
  }

  return theresult;
};

function generateRootRandomOperationSeries(
  operations,
  config,
  init = 0,
  lowerlimit = 0,
  upperlimit = 100,
  ispan = 0,
  fspan = 0
) {
  /* console.log('generateRootRandomOperationSeries:',
    'operations:', operations,
    'config:', config,
    'init:', init,
    'lowerlimit:', lowerlimit,
    'upperlimit:', upperlimit,
    'ispan:', ispan,
    'fspan:', fspan
  ); */

  const theresult: Array<any> = [];

  if (init && config && ispan && fspan) {
    theresult.push(init);
    shuffle(operations);
    const numops = operations.length;

    const basedigits = new String(init).length;
    let digitsarray: Array<any> = [];

    let numoperations = 0;
    for (let idx = 0; idx < config.length; idx++) {
      const aConf = config[idx];
      for (
        let i = 0;
        i < (basedigits == aConf.digits ? aConf.num - 1 : aConf.num);
        i++
      )
        digitsarray.push(aConf.digits);
    }
    shuffle(digitsarray);
    numoperations = digitsarray.length + 1;

    const prevnums = [init];
    for (let i = 1; i < numoperations; i++) {
      let anop = operations[(i - 1) % numops];
      let aNum = 0;

      if (digitsarray) {
        if (digitsarray[i - 1] < basedigits) {
          const is = Math.pow(10, digitsarray[i - 1] - 1);
          const fs = Math.pow(10, digitsarray[i - 1]) - 1;
          aNum = generateRandomNumber(is, fs, prevnums);
        } else aNum = generateRandomNumber(ispan, fspan, prevnums);
      } else aNum = generateRandomNumber(ispan, fspan, prevnums);

      prevnums.push(aNum);

      let nextresult = 0;
      // console.log('To eval:', theresult.join('') + (i > 0 ? anop : '') + aNum);
      nextresult = eval(theresult.join('') + (i > 0 ? anop : '') + aNum);

      if (anop == '-' || anop == '/') {
        if (nextresult < lowerlimit)
          anop = anop == '-' ? '+' : anop == '/' ? '*' : anop;
      } else {
        if (nextresult > upperlimit)
          anop = anop == '+' ? '-' : anop == '*' ? '/' : anop;
      }

      theresult.push((i > 0 ? anop : '') + aNum);
    } // del for
  } // del if

  // console.log('---------------theresult:', theresult);


  return theresult.join('');
}

export const shuffle = a => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

export const getSeriesFromCouples = (
  couples,
  num,
  init,
  finish,
  ops,
  config?,
  ispan?,
  fspan?,
  lowerlimit?,
  upperlimit?,
  startwith0?
) => {
  /* console.log(
    'getSeriesFromCouples - couples:', couples, 'num:', num, 'init:', init, 'finish:', finish, 'ops:', ops, 'config:', config, 'ispan:', ispan, 'fspan:', fspan, 'lowerlimit:', lowerlimit, 'upperlimit:', upperlimit, 'startwith0:', startwith0
  ); */

  let series: any = [];
  if (couples === 'random') {
    if (ops) {
      if (config) {
        for (let idx = 0; idx < num; idx++) {
          const initial = generateRandomNumber(init, finish, null);
          let anOpSeries = generateRootRandomOperationSeries(
            ops,
            config,
            initial,
            lowerlimit,
            upperlimit,
            ispan,
            fspan
          );

          if (startwith0) anOpSeries = '0+' + anOpSeries;

          series.push(anOpSeries);
        }
      } else
        series = shuffle(
          generateRandomOperations(ops, num, init, finish, ispan, fspan)
        );
    } else {
      series = generateRandomNumberSeries(num, init, finish, null);
    }
  } else {
    series = shuffle(couples);
  }

  series = series.slice(0, num);

  // console.log('getSeriesFromCouples series:', series);

  return series;
};

export const vh = percent => {
  var h = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  return (percent * h) / 100;
};

export const vw = percent => {
  var w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return (percent * w) / 100;
};

export const isPortrait = () => {
  return window.innerHeight > window.innerWidth;
};

export const randompositivenegative = () => {
  return Math.round(Math.random()) * 2 - 1;
};

export const isValidActiveClient = (user) => {
  const now = moment().format('YYYY-MM-DD');
  const userExpiration = user && user.expirationDate ? moment(user.expirationDate).format('YYYY-MM-DD') : '3000-01-01';
  const isActiveClient = user && user.isClient && userExpiration >= now;
  return isActiveClient;
}

export const userHasSubscription = (transactions, user) => {

  const isActiveClient = isValidActiveClient(user);
  console.log('utils.userHasSubscription:', transactions, user, isActiveClient);

  /* REAL */
  let hasSubscription = (transactions && transactions.length > 0) || isActiveClient;

  /* test NO SUBSCRIPTIONS */
  // hasSubscription = false;

  /* test with SUBSCRIPTIONS */
  // hasSubscription = true;

  return hasSubscription;
}

const isPrime = num => {
  for (let i = 2, s = Math.sqrt(num); i <= s; i++) {
    if (num % i === 0) return false;
  }
  return num > 1;
}

const getDivisors = (integer) => {

  var result: any = [];
  for (let i = 2; i < integer; i++) {
    if (integer % i == 0) {
      result.push(i);
    }
  }
  return result;
};

export const generateEquation = (numOps, init, finish, permOps?) => {
  if (numOps >= 1) {
    let operations = permOps ? permOps : [...OPERATIONS];
    // console.log('generateEquation: operations', operations);

    operations = shuffle(operations);

    const numbers: any = [];
    const ops: any = [];

    const n0 = rand(init, finish);
    numbers.push(n0);

    // console.log('generateEquation: n0:', n0, ':: numbers:', numbers, ':: ops:', ops);

    let nact = n0;
    for (let idx = 0; idx < numOps; idx++) {

      const shuffledOps = shuffle(operations);
      let op = shuffledOps[0];
      let ni = 0;
      if (op == '+') {
        const d1 = nact != 1 ? rand(1, nact - 1) : 1;
        const d2 = nact - d1;
        // console.log('generateEquation +:', nact, ':', d1, d2);

        ni = d1;
        numbers[idx] = d2;
      }
      else if (op == '-') {
        const d1 = nact != finish ? rand(nact + 1, finish) : finish;
        const d2 = d1 - nact;
        // console.log('generateEquation -:', nact, ':', d1, d2);
        ni = d1;
        numbers[idx] = d2;
      }
      else if (op == '*') {
        const isPrm = isPrime(nact);
        let d1 = 0;
        let d2 = 0;

        if (isPrm) {
          op = '+';
          d1 = rand(1, nact);
          d2 = nact - d1;
        } else {
          if (nact === 1) {
            d1 = d2 = 1;
          } else {
            const divs = getDivisors(nact);
            d1 = shuffle(divs)[0];
            d2 = nact / d1;
          }
        }
        // console.log('generateEquation *', isPrm, '::nact:', nact, '::ds:', d1, d2);

        ni = d1;
        numbers[idx] = d2;

      }
      else if (op == '/') {
        const d1 = rand(2, 20);
        const d2 = d1 * nact;
        ni = d2;
        // console.log('generateEquation /', nact, ':', d1, d2);

        numbers[idx] = d1;
      }

      numbers.push(ni);
      ops.push(op);
      operations = shuffledOps.slice(1);
      if (operations.length == 0) operations = permOps ? permOps : [...OPERATIONS];
      nact = ni;
      // console.log('generateEquation: op:', op, ':: numbers:', numbers, ':: ops:', ops);
    }

    // console.log('generateEquation: numbers:', numbers, ':: ops:', ops);

    numbers.reverse();
    ops.reverse();

    // console.log('generateEquation: numbers:', numbers, ':: ops:', ops);

    let finalEq = numbers[0];
    for (let idy = 1; idy < numbers.length - 1; idy++) {
      finalEq = `(${finalEq} ${ops[idy - 1]} ${numbers[idy]})`;
    }
    finalEq += ` ${ops[ops.length - 1]}  ${numbers[numbers.length - 1]}`;

    // console.log('generateEquation: finalEq:', finalEq);

    return finalEq;


  } else return '';
}

export const generateSimpleEquation = (numOps, init, finish, permOps?) => {
  if (numOps >= 1) {
    let operations = permOps ? permOps : [...OPERATIONS];
    operations = shuffle(operations);

    const numbers: any = [];
    const ops: any = [];

    const n0 = rand(init, finish);
    numbers.push(n0);

    // console.log('generateSimpleEquation: n0:', n0, ':: numbers:', numbers, ':: ops:', ops);

    let nact = n0;
    for (let idx = 0; idx < numOps; idx++) {

      const shuffledOps = shuffle(operations);
      let op = shuffledOps[0];
      let ni = 0;
      if (op == '+') {
        const d1 = nact != 1 ? rand(1, nact - 1) : 1;
        ni = d1;
        // numbers[idx] = d2;
      }
      else if (op == '-') {
        const d1 = nact != finish ? rand(nact + 1, finish) : finish;
        ni = d1;
        // numbers[idx] = d2;
      }
      else if (op == '*') {
        const isPrm = isPrime(nact);
        let d1 = 0;
        let d2 = 0;

        if (isPrm) {
          // console.log('Es primo!!!!');
          nact++;
        }

        const divs = getDivisors(nact);

        d1 = shuffle(divs)[0];
        d2 = nact / d1;

        ni = d1;
        numbers[idx] = d2;

      }
      else if (op == '/') {
        const d1 = rand(2, 20);
        const d2 = d1 * nact;
        ni = d2;
        numbers[idx] = d1;
      }

      numbers.push(ni);
      ops.push(op);
      operations = shuffledOps.slice(1);
      if (operations.length == 0) operations = permOps ? permOps : [...OPERATIONS];
      nact = ni;
      // console.log('generateSimpleEquation: op:', op, ':: numbers:', numbers, ':: ops:', ops);
    }

    // console.log('generateSimpleEquation: numbers:', numbers, ':: ops:', ops);

    numbers.reverse();
    ops.reverse();

    // console.log('generateSimpleEquation: numbers:', numbers, ':: ops:', ops);

    const finalEq: any = [];

    finalEq.push(numbers[0]);
    for (let idy = 1; idy < numbers.length - 1; idy++) {
      finalEq.push(ops[idy - 1]);
      finalEq.push(numbers[idy]);
    }
    finalEq.push(ops[ops.length - 1]);
    finalEq.push(numbers[numbers.length - 1]);

    // console.log('generateSimpleEquation: finalEq:', finalEq);


    return finalEq;


  } else return null;
}

export const generateEquationSeries = (num, numOps, init, finish, permOps?, type?) => {
  // console.log('generateEquationSeries:', num, numOps, init, finish, permOps, type);

  const equations: any = [];

  for (let idx = 0; idx < num; idx++) {
    // console.log('----- generateEquationSeries idx:', idx);

    if (!type || type != 'simple') equations.push(generateEquation(numOps, init, finish, permOps));
    else equations.push(generateSimpleEquation(numOps, init, finish, permOps));
  }

  return equations;

}

export const openLink = (url) => window.open(url, '_blank')?.focus();