import React, { useState } from 'react'

import { Row, Col, Button, Progress } from 'antd';

import './index.less';
import VideoView from '../../base/video';
import ChangeOrientation from '../../Utils/ChangeOrientation';
import { t } from '../../../languages';
import PDFViewer from '../../base/pdfviewer';

const handleAccept = (props) => {
    const uid = props.uid;
    const eid = props.eid;
    const coins = props.params.coins;

    props.saveResult(uid, `${uid}-${eid}`, { id: 'T', uid, eid, p: 1, h: 1, e: 0, c: coins, r: [1], s: [1] });
    props.handleCancel();
}

const Theory = (props) => {
    // console.log('Theory:', props);

    const lang = props.lang;
    const [ended, setEnded] = useState(false);

    const video = props.params.video;
    const text = props.params.text;
    const content = text ? t(lang, text.content).replace(/(?:\r\n|\r|\n)/g, '<br><br>') : null;
    const pdf = props.params.pdf;


    return (
        <Row className="exercise">
            {window.cordova ? <ChangeOrientation to={'landscape'} /> : null}

            <Col span={22} className='progress-bar'>
                <Progress percent={0} showInfo={false} />
            </Col>

            <Col span={24} className="exercise-body">
                {text ? <section className="theory text">
                    <h2>{t(lang, text.title)}</h2>
                    <p dangerouslySetInnerHTML={{ __html: content }} />
                </section> : null}

                {video ? <section className="theory">
                    <div className='overyt'></div>
                    <VideoView
                        videoId={props.params.video}
                        className="video"
                        endHandler={() => setEnded(true)}
                        pauseHandler={() => console.log("paused")}
                        playHandler={() => console.log("play")}
                    />
                    <div className='loveryt'></div>
                </section> : null}

                {pdf ? <section className="theory pdf">
                    <PDFViewer pdf={pdf} />
                </section> : null}

            </Col>
            <Col span={24}>
                {ended ? <Row className={'botones theory'}>
                    <Col span={24}><Button key="submit" type="primary" onClick={() => handleAccept(props)} style={{ 'width': '90%' }} disabled={false}>{t(props.lang, 'accept')}</Button></Col>
                    <Col span={24}></Col>
                </Row> : null}
            </Col>
        </Row>
    );
};

export default Theory;
