import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { t } from '../../../languages/index';

const Step4 = ({ lang, user }) => {

    const [next, setNext] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setNext(true);
        }, 2000);
    }, []);

    
    if (next) return <Redirect to="/" />
    else return (
        <section className="register almostthere">
            <Spin size="large" className="spinner" />
            <div className='body'>
                <img src="./static/snapshots/abaco.png" />
                <br />
                <br />
                <h2>{t(lang, 'loading')}...</h2>
                <p>{t(lang, 'almostthere')}</p>
                <p>{t(lang, 'preparing')}</p>
            </div>
        </section>
    );
};
export default Step4;