// import fetch from 'isomorphic-fetch';
// import { API_ROOT, version } from '../../api-config';

import 'cordova-plugin-purchase';
import { getMobileOperatingSystem } from '../../utils';
import { updateUserFields } from '../user';
import moment from 'moment';

declare let window: any;

const getStoredProducts = () => {
  const userstr = localStorage.getItem('products');
  return userstr ? JSON.parse(userstr) : null;
};

const storeProducts = (products: any) => {
  localStorage.setItem('products', JSON.stringify(products));
};

const destoreProducts = () => {
  localStorage.removeItem('products');
};

const getStoredTransactions = () => {
  const userstr = localStorage.getItem('transactions');
  return userstr ? JSON.parse(userstr) : null;
};

const storeTransactions = (transactions: any) => {
  localStorage.setItem('transactions', JSON.stringify(transactions));
};

const destoreTransactions = () => {
  localStorage.removeItem('transactions');
};

export const BASEPRODUCTS = {
  android: ['001monthly', '002yearly', '003quarterly'],
  ios: ['i01monthly', 'i02yearly', 'i03quarterly']
};

export const SUBSCRIPTIONS_REQUESTED = 'user/SUBSCRIPTIONS_REQUESTED';
export const SUBSCRIPTIONS = 'subscription/SUBSCRIPTIONS';

const initialState = {
  isLoading: false,
  error: false,
  saved: false,
  products: null,
  transactions: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTIONS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: false
      };

    case SUBSCRIPTIONS:
      return {
        ...state,
        products: action.products,
        transactions: action.transactions,
        error: false
      };

    default:
      return state;
  }
};

export const loadSubscriptions = () => {
  console.log('loading Subscriptions...');
  return (dispatch, getState) => {
    if (window.cordova) {

      const CdvPurchase = window.CdvPurchase;
      const storeState = getState();
      // console.log('loading Subscriptions dispatch...', CdvPurchase, storeState);
      const _subscription = storeState.subscription;

      if (CdvPurchase && !_subscription.isLoading) {
        dispatch({
          type: SUBSCRIPTIONS_REQUESTED
        });

        const { store, ProductType, Platform, LogLevel } = CdvPurchase;

        const os = getMobileOperatingSystem().toLocaleLowerCase();
        const _BASEPRODUCTS = BASEPRODUCTS[os];

        const _PLATFORM = os == 'ios' ? Platform.APPLE_APPSTORE : Platform.GOOGLE_PLAY;

        const products = _BASEPRODUCTS ? _BASEPRODUCTS.map(aPName => ({
          type: ProductType.PAID_SUBSCRIPTION,
          id: aPName,
          platform: _PLATFORM
        })) : null;

        // console.log('Subscriptionsproducts:', os, products);

        // We should first register all our products or we cannot use them in the app.
        if (products) {
          store.register(products);

          // store.verbosity = LogLevel.DEBUG;
          store.verbosity = LogLevel.INFO;

          store.applicationUsername = () => 'misoroban';

          // Show errors on the dedicated Div.
          store.error(errorHandler);

          store
            .when()
            .productUpdated(() =>
              loadMyProductsAndTransactions(products, dispatch, getState)
            )
            .approved(transaction =>
              finishPurchase(transaction, dispatch, getState)
            );

          store.initialize([_PLATFORM]);
        }

      }
    }
  };
};

const loadMyProductsAndTransactions = (products, dispatch, getState) => {
  // console.log('loadMyProductsAndTransactions...', products);

  const storeUser = getState().user;
  const _user = storeUser.user;

  const { store, Platform } = window.CdvPurchase;

  const os = getMobileOperatingSystem().toLocaleLowerCase();
  const _PLATFORM = os == 'ios' ? Platform.APPLE_APPSTORE : Platform.GOOGLE_PLAY;

  const myProducts: Array<any> = [];
  const myTransactions: Array<any> = [];

  for (const aProduct of products) {
    const myLocalProduct: any = store.get(aProduct.id, _PLATFORM);
    myProducts.push(myLocalProduct);
    const myLocalTransaction = store.findInLocalReceipts(myLocalProduct);
    if (myLocalTransaction) myTransactions.push(myLocalTransaction);

  }

  // const today = moment().format('YYYY-MM-DD');
  console.log('loadMyProductsAndTransactions myProducts:', myProducts, 'myTransactions::', myTransactions, ':: _user: ', _user);

  if (myTransactions.length === 0 && _user && _user.isClient && ['android', 'ios'].includes(_user.clientSource)) {
    dispatch(updateUserFields({ isClient: false }));
  }

  dispatch({
    type: SUBSCRIPTIONS,
    products: myProducts,
    transactions: myTransactions
  });


};

const finishPurchase = (transaction, dispatch, getState) => {
  // console.log('finishPurchase transaction:', transaction);
  transaction.finish();

  const storeSubscription = getState().subscription;
  const _products = storeSubscription.products;

  const { TransactionState } = CdvPurchase;

  const storeUser = getState().user;
  const _user = storeUser.user;

  // console.log('finishPurchase store state:', transaction.state, _products, _user);

  if (transaction.state == TransactionState.APPROVED || transaction.state == TransactionState.FINISHED) {

    const os = getMobileOperatingSystem().toLocaleLowerCase();
    const _BASEPRODUCTS = BASEPRODUCTS[os];

    let toAdd = 3;
    if (transaction.products[0].id === _BASEPRODUCTS[0]) toAdd = 1;
    else if (transaction.products[0].id === _BASEPRODUCTS[1]) toAdd = 3;
    else if (transaction.products[0].id === _BASEPRODUCTS[2]) toAdd = 12;

    const newExpDate = moment().add(toAdd, 'months').format('YYYY-MM-DD');

    console.log('finishPurchase expData:', _user.expirationDate, newExpDate);

    if (!_user.isClient || (_user.isClient && _user.expirationDate < newExpDate)) {
      dispatch(updateUserFields({ isClient: true, expirationDate: newExpDate, clientSource: os }));
    }
  }

  loadMyProductsAndTransactions(_products, dispatch, getState);
};

export const purchase = aProduct => {
  // console.log('Subscription purchase:', aProduct);
  if (aProduct) aProduct.getOffer().order();
};

const errorHandler = error => {
  // console.log('errorHandler:', error);
};

export const manageSubscriptions = () => {
  if (window.CdvPurchase) {
    const { store, Platform } = window.CdvPurchase;
    const os = getMobileOperatingSystem().toLocaleLowerCase();
    const _PLATFORM = os == 'ios' ? Platform.APPLE_APPSTORE : Platform.GOOGLE_PLAY;

    store.manageSubscriptions(_PLATFORM);
  }
};

export const restorePurchases = () => {
  // console.log('restorePurchases...');
  return (dispatch, getState) => {

    if (window.CdvPurchase) {

      const storeState = getState();
      const _subscription = storeState.subscription;
      const _products = _subscription.products;

      const { store, Platform } = window.CdvPurchase;

      store.restorePurchases()
        .then(() => {
          // console.log('restorePurchases: purchases restored::', _products);
          // store.update();
          dispatch(loadSubscriptions());
        })
        .catch(err => {
          console.error('restorePurchases: failed to restore purchases: ' + err);
        });
    }
  }

}

export const getProductsDetails = (productId) => {

  return (dispatch, getState) => {

    if (window.CdvPurchase) {

      const storeState = getState();
      const _subscription = storeState.subscription;
      const _products = _subscription.products;
      if (_products && _products.length > 0) {
        const product_details = {};

        for (let idx = 0; idx < _products.length; idx++) {
          const aProduct = _products[idx];
          const offers = aProduct.offers;
          let pricingPhases = { priceMicros: 0, currency: 'EUR' };
          if (offers.length > 1) pricingPhases = offers[1].pricingPhases[0];
          else pricingPhases = offers[0].pricingPhases[0];

          product_details[aProduct.id] = {
            id: aProduct.id,
            description: aProduct.description,
            pricing: { priceMicros: pricingPhases.priceMicros, currency: pricingPhases.currency }
          }
        }

        return product_details;

      }

      return null;

    }
  }

}
