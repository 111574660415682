const tutor1 = [
    /**level 1: base, suma resta sencillas **/
    [
        'general_welcome_l1_welcome',
        't1_feedback_ok_l2_welcome',
        't1_feedback_nok',
    ],

    /**level 2: suma y resta 5 **/
    [
        't2_feedback_ok_l3_welcome',
        't2_feedback_nok',
    ],

    /**level 3: decenas y suma resta 10 **/
    [
        't3_feedback_ok_l4_welcome',
        't3_feedback_nok',
    ],

    /**level 4: centenas y suma resta 2c **/
    [
        't4_feedback_ok_l5_welcome',
        't4_feedback_nok',
    ],

    /**level 5: suma resta 3c **/
    [
        'completed_training',
        't5_feedback_nok',
    ],
];


const tutor2 = [
    /**level 1: base, suma resta sencillas **/

    /**level 2: suma y resta 5 **/

    /**level 3: decenas y suma resta 10 **/

    /**level 4: centenas y suma resta 2c **/

    /**level 5: suma resta 3c **/

    /**level 6: multiplicacionnes 1 c **/

    /**level 7: divisiones 1 c **/

    /**level 8: sumas 4c **/

];

const tutor3 = [
    /**level 1: base, suma resta sencillas **/

    /**level 2: suma y resta 5 **/

    /**level 3: decenas y suma resta 10 **/

    /**level 4: centenas y suma resta 2c **/

    /**level 5: suma resta 3c **/

    /**level 6: multiplicacionnes 1 c **/

    /**level 7: divisiones 1 c **/

    /**level 8: sumas 4c **/

    /**level 9: mult 2c*/

    /**level 10: div 2c*/

];


const tutor = {
    1: tutor1,
    2: tutor2,
    3: tutor3,
}

export default tutor;